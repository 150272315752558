<template>
  <div class="vx-row">
    <div class="vx-col w-full">
        <vx-card title="Map Shipments">
            <span v-if='!internal_driver_id'>
                
                <div class="vx-row mb-5">
                    <div class="vx-col md:w-1/2">
                      <browse-contact :isdriver="true" v-validate="'required'" name="driver_id" data-vv-as="Default driver" label="Driver" v-model="driver_id" @input="changeDriver"></browse-contact>
                    </div>
                    <!-- <div class="vx-col mt-6 md:w-1/2">
                      OR &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <vs-button @click="showAll" color="primary">Show All</vs-button>
                    </div> -->
                </div>
                <div class="vx-row mb-5">
                  <div class="vx-col w-full flex mb-3">
                    <vs-checkbox v-model="is_driver" @change="filterVisibleChange()">Driver</vs-checkbox>
                    <vs-checkbox v-model="is_vehicle" @change="filterVisibleChange()">Vehicle</vs-checkbox>
                  </div>
                </div>
            </span>
            <div class="w-full">
                <vs-divider>Location</vs-divider>
                <map-with-marker v-model="markerLatLng" :markerLatLng="markerLatLng" :additionalLatLng="additionalLatLng" :is_draggable="false" :centerLatLng="centerLatLng" :zoom="zoom" :with_tooltip="true"></map-with-marker>
            </div>
        </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import BrowseContact from '@/views/browse_input/Contact.vue'
import MapWithMarker from '@/views/components/MapWithMarker.vue';

export default {
  props : ['internal_driver_id'],
  components:{
      BrowseContact,
      MapWithMarker
  },
  data(){
    return {
      intervalDriver: null,
      intervalVehicle: null,
      driver_id: null,
      centerLatLng: [-0.741377057653512, 113.74428916957208],
      zoom: 5,
      markerLatLngAll: [],
      markerLatLng: [],
      additionalLatLngAll: [],
      additionalLatLng: [],
      is_driver: true,
      is_vehicle: true
    }
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'map_shipments/index',
      dispatchVehicles: 'vehicles/show'
    }),
    // showAll() {
    //   this.zoom = 5;
    //   this.centerLatLng = [-0.741377057653512, 113.74428916957208];
    //   this.markerLatLng = this.markerLatLngAll;
    //   this.driver_id = null;
    // },
    async showVehicles() {
      let {data} = await this.dispatchVehicles('locations')
      let name
      for(let d in data) {
        name = 'Vehicle : ' + data[d].vehiclenumber
        data[d].name = name
        data[d].description = '<li>IMEI : ' + data[d].imei + '</li>'
        data[d].description += '<li>Status : ' + data[d].status + '</li>'
        data[d].description += '<li>Last Update : ' + data[d].timeformat + '</li>'
        data[d].description  = '<ul>' + data[d].description + '</ul>'
      }
      this.additionalLatLngAll = data
      // this.additionalLatLng = data

      if(this.is_vehicle) {
        this.additionalLatLng = this.additionalLatLngAll;
      }
      else {
        this.additionalLatLng = [];
      }
    },
    changeDriver(data){
      if(data != null) {
        if(this.is_driver) {
          let dataFilter = this.markerLatLngAll.filter(marker => {
            return marker.contact_id === data;
          });
          this.driver_id = data;
          if(dataFilter.length > 0) {
            this.centerLatLng = [dataFilter[0].lat, dataFilter[0].lng];
            this.markerLatLng = [dataFilter[0]];
          }
          else {
            this.zoom = 5;
            this.centerLatLng = [-0.741377057653512, 113.74428916957208];
            this.markerLatLng = [];
          }
        }
        else {
          this.zoom = 5;
          this.centerLatLng = [-0.741377057653512, 113.74428916957208];
          this.markerLatLng = [];
        }
      }
      else {
        this.zoom = 5;
        this.centerLatLng = [-0.741377057653512, 113.74428916957208];
        this.markerLatLng = this.markerLatLngAll;
        this.driver_id = null;
      }
    },
    async getData(){
      let payload = null;
      let {data} = await this.dispatchIndex(payload);
      for(let d in data) {
        data[d].contact_name = 'Driver : ' + data[d].contact_name
      }
      this.markerLatLngAll = data;
      // this.markerLatLng = data;
      
      if(this.is_driver) {
        this.changeDriver(this.driver_id);
      }
      else {
        this.markerLatLng = [];
      }
    },
    filterVisibleChange() {
      if(this.is_driver) {
        this.changeDriver(this.driver_id);
      }
      else {
        this.markerLatLng = [];
      }

      if(this.is_vehicle) {
        this.additionalLatLng = this.additionalLatLngAll;
      }
      else {
        this.additionalLatLng = [];
      }
    },
  },
  async mounted(){
    if(this.internal_driver_id) {
        this.is_driver = true
        this.is_vehicle = false
        this.driver_id = this.internal_driver_id
    }
    await this.getData();
    await this.showVehicles();
    this.intervalDriver = setInterval(await this.getData, 10000);
    this.intervalVehicle = setInterval(await this.showVehicles, 10000);
  },
  destroyed() {
    clearInterval(this.intervalDriver);
    clearInterval(this.intervalVehicle);
  }
}
</script>

<style lang="scss">
  // .map {
  //   height: 400px;
  //   width: 100%;
  // }
</style>